h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

:root {
  --bg-primary-color: #171332;
  --bg-secondary-color: #eeeeee;
  --bg-tertiary-color: #dfdfdf;

  --text-primary-color: #fdf6fa;
  --text-secondary-color: #fdf6fa;

  --primary-color: #f96d00;
  --secondary-color: #c82586;
}

.layout {
  width: 100%;
  height: 100vh;
}

.header {
  height: 3rem;
  padding: 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0.5rem;
  border-radius: 0.25rem;
}

.sider {
  overflow-x: hidden;
  border-radius: 0.25rem;
}

.siderLeft {
  margin: 0.5rem 0 0.5rem 0.5rem;
}

.siderRight {
  margin: 0.5rem 0.5rem 0.5rem 0;
}
